import axios from "axios"

export class ServicoPerfisAcesso {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/PerfisAcesso` //process.env.VUE_APP_URL_TRIG_GESTOR
  }
  buscarPerfis() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  buscarPorIdParaEditar(id) {
    return axios({
      url: this.urlServico + `/GetByIdParaEditar?id=${id}`,
      method: "GET"
    })
  }
  buscarMenusSelect() {
    return axios({
      url: this.urlServico + `/GetMenusSelect`,
      method: "GET"
    })
  }
  salvar(idRole, perfil, menus) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(this.urlServico + "/Salvar", { idRole: idRole, perfil: perfil, menus: menus }, config)
      .catch(error => {
        alert("Erro ao salvar um novo cargo " + error)
        return Promise.reject(error)
      })
  }
}
