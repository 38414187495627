<template>
  <v-select
    autocomplete
    label="Perfil de acesso"
    placeholder="Selecione..."
    :rules="[() => !!codPerfilAcesso || 'Campo obrigatório']"
    v-model="codPerfilAcesso"
    :items="perfisAcesso"
    item-value="id"
    @input="setSelected"
    item-text="name"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoPerfisAcesso } from "../../servicos/servicoPerfisAcesso"
const servicoPerfisAcesso = new ServicoPerfisAcesso()
export default {
  data: () => ({
    perfisAcesso: [],
    codPerfilAcesso: ""
  }),
  created() {
    serverBus.$on("carregarSelecionado", value => {
      this.codPerfilAcesso = value
    })
    servicoPerfisAcesso.buscarPerfis().then(
      res => {
        if (res.status === 200) {
          this.perfisAcesso = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("perfilSelected", value)
    }
  }
}
</script>
