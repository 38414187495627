import axios from "axios"

export class ServicoUsuario {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Usuario` //process.env.VUE_APP_URL_TRIG_GESTOR
  }
  buscarUsuarios() {
    return axios({
      url: this.urlServico + `/GetAllUsers`,
      method: "GET"
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  Salvar(id, idFuncionario, username, senha, idPerfilAcesso) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios
      .post(
        this.urlServico + "/Salvar",
        { Id: id, IdFuncionario: idFuncionario, Usuario: username, Senha: senha, IdIdentityRole: idPerfilAcesso },
        config
      )
      .catch(error => {
        alert("Erro ao salvar um novo romaneio " + error)
        return Promise.reject(error)
      })
  }
}
