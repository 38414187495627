<template>
  <v-form ref="form">
    <v-flex lg12 sm12 xs12>
      <div class="d-flex">
        <v-text-field
          class="mr-2"
          label="Nome de usuário"
          name="username"
          placeholder="Nome de usuário"
          v-model="username"
          :rules="usernameRules"
          :counter="50"
          required
        ></v-text-field>
        <v-text-field
          v-model="senha"
          :rules="senhaRules"
          :type="show1 ? 'text' : 'password'"
          label="Senha"
          :append-outer-icon="show1 ? 'visibility' : 'visibility_off'"
          :append-outer-icon-cb="mostrarIcone"
        >
        </v-text-field>
      </div>
      <div class="d-flex">
        <select-funcionario class="mr-2"></select-funcionario>
      </div>
      <div class="d-flex">
        <select-perfil class="mr-2"></select-perfil>
      </div>
    </v-flex>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="clear">Limpar</v-btn>
      <v-btn outline @click="voltar">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import ProgressCircular from "../progressCircular/progressCircular"
import SelectFuncionario from "../funcionario/selectFuncionario.vue"
import SelectPerfil from "../perfisAcesso/selectPerfilAcesso.vue"
import { ServicoUsuario } from "../../servicos/servicoUsuario"
const servicoUsuario = new ServicoUsuario()
export default {
  components: {
    ProgressCircular,
    SelectFuncionario,
    SelectPerfil
  },
  data: () => ({
    show1: false,
    id: null,
    username: "",
    senha: "",
    idFuncionario: null,
    idPerfilAcesso: null,
    snackbar: false,
    text: "",
    usernameRules: [v => !!v || "Campo obrigatório"],
    senhaRules: [
      v => !!v || "Campo obrigatório",
      v => v.length >= 3 || "A senha deve conter no mínimo 6 caracteres",
      v => /[a-z]+/.test(v) || "A senha deve conter ao menos 1 letra minúscula",
      v => /[A-Z]+/.test(v) || "A senha deve conter ao menos 1 letra maiúscula"
    ]
  }),
  created() {
    serverBus.$on("funcionarioSelected", value => {
      this.idFuncionario = value
    })
    serverBus.$on("perfilSelected", value => {
      this.idPerfilAcesso = value
    })

    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoUsuario.buscarPorId(this.id).then(res => {
        if (res.status === 200 && res.data.length === undefined) {
          this.id = res.data.id
          this.idFuncionario = res.data.idFuncionario
          this.idPerfilAcesso = res.data.idIdentityRole
          this.senha = res.data.senha
          this.username = res.data.usuario
          serverBus.$emit("carregarSelecionado", this.idPerfilAcesso)
          serverBus.$emit("carregarFuncionarioSelecionado", this.idFuncionario)
        }
      })
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.abrirProgressCircular()
        servicoUsuario.Salvar(this.id, this.idFuncionario, this.username, this.senha, this.idPerfilAcesso).then(
          res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.voltar()
            } else {
              this.fecharProgressCircular()
              this.snackbar = true
              this.text = res.data[0].title + " - " + res.data[0].detail
            }
          },
          () => this.fecharProgressCircular()
        )
      }
    },
    mostrarIcone() {
      this.show1 = !this.show1
    },
    clear() {
      this.username = ""
      this.senha = ""
      this.$refs.form.resetValidation()
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "Usuario" })
    }
  }
}
</script>
